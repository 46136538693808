//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {SET_TOKEN} from '~/store/auth/types.auth'

export default {
  name: 'Auth',
  components: {
    Modal: () => import('~/components/UI/Modal/Modal.vue'),
    AuthLogin: () => import('~/components/pages/Auth/components/AuthLogin.vue'),
    SignUp: () => import('~/components/pages/Auth/components/SignUp.vue'),
    SignUpSuccess: () => import('~/components/pages/Auth/components/SignUpSuccess.vue'),
    AuthForgotPass: () =>
      import('~/components/pages/Auth/components/AuthForgotPass.vue'),
    AuthCongratulations: () =>
      import('~/components/pages/Auth/components/AuthCongratulations.vue'),
    AuthNewPass: () =>
      import('~/components/pages/Auth/components/AuthNewPass.vue'),
  },
  layout: 'empty',
  data() {
    return {
      infoMessage: '',
      infoMessageType: 'success',
      message: '',
      mail: '',
      typeCongratulation: '',
    }
  },
  computed: {
    authType() {
      const query = this.$route.query.type

      if (this.$route.query.token) {
        this.authWhenTokenExists(this.$route.query.token)
      }

      return query
    },
  },
  async mounted() {
    const {activate, userToken} = this.$route.query
    if (activate) {
      try {
        await this.$api.auth.activateUser(userToken)
        this.infoMessage = this.$t('thankForActivate') + '\n' + this.$t('yourAccountIsActivated')
        this.infoMessageType = 'success'
        this.showInfoModal()
      } catch (err) {
        this.infoMessage = this.$t('errorActive')
        this.infoMessageType = 'error'
        this.showInfoModal()
      } finally {
        await this.$router.push(this.$route.path)
      }
    }
  },
  methods: {
    showInfoModal() {
      setTimeout(() => {
        this.$refs.modalInfo.onOpen()
      }, 0)
    },

    makeToast(variant = null) {
      this.$bvToast.toast(this.message, {
        autoHideDelay: 3000,
        variant,
        solid: true,
      })
    },

    setState(mail, state) {
      this.mail = mail
      this.typeCongratulation = state
    },

    authWhenTokenExists(token) {
      this.$store.commit(`auth/${SET_TOKEN}`, {token})
      this.$api.auth
        .testToken()
        .then(() => {
          this.$router.push('/')
        })
        .catch((err) => {
          this.message = err.response.data.message
          this.makeToast('danger')
          this.errors = {
            message: this.$t('invalidToken'),
          }
        })
    },
  },
}
