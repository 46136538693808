//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import Counter from '~/components/UI/Counter.vue'
import { IS_MOBILE } from '~/store/device/type.device'
import AddPromocode from '~/components/modules/Cart/AddPromocode.vue'
import ApplyLoyaltyProgram from '~/components/modules/Cart/ApplyLoyaltyProgram.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'
import LoginModal from '~/components/modules/ModalWindows/Auth/LoginModal.vue'

export default {
  components: {
    Counter,
    Breadcrumbs,
    AddPromocode,
    ApplyLoyaltyProgram,
    LoginModal,
    ProductPrice,
  },
  // middleware: ['auth'],
  async asyncData({ $api, store, localePath }) {
    store.commit('bc/SET_BC', [
      { name: 'Cart', slug: localePath({ name: 'Cart' }) },
    ])

    const user = store.getters['user/GET_USER']
    try {
      let responseLoyalty = null
      if (user) {
        responseLoyalty = await $api.loyalty.getLoyaltyProgram()
      }
      const response = await $api.cart.getItemsCart()
      const items = response.data.items.map((item) => {
        return {
          ...item,
          price_type: {
            brutto: item.brutto,
            discount_brutto: item.brutto_discount,
            netto: item.netto,
            discount_netto: item.netto_discount,
          },
        }
      })
      const cart = {
        loyalty_points: response.data.loyalty_points,
        loyalty_points_discount: response.data.loyalty_points_discount,
        promo_code: response.data.promo_code,
        promo_code_discount: response.data.promo_code_discount,
        promo_code_type: response.data.promo_code_type,
        total_brutto: response.data.total_brutto,
        total_netto: response.data.total_netto_without_discount,
        total_vat: response.data.total_vat,
        items,
      }
      return {
        loyalty: responseLoyalty && responseLoyalty.data,
        products: cart,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      debounce:null,
      loyalty: {},
      products: [],
      disabledDec: false,
      disabledInc: false,
      selectProduct: '',
      priceType: null,

    }
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    getPromocodePercent() {
      return (this.products.total_netto + this.products.total_vat) / 100 * 10
    },
    visibleCart() {
      return this.products.length !== 0 ? this.products.items.length : false
    },
    getPromocodeDiscount() {
      if(this.products.promo_code_type === 1 && this.products.promo_code_discount) {
        return this.getPromocodePercent
      }
      if(this.products.promo_code_type === 2 && this.products.promo_code_discount) {
        return this.products.promo_code_discount
      }

      return 0
    },
    toPayment() {
      return this.products.loyalty_points_discount >=
        (this.products.total_vat + this.products.total_netto) - this.getPromocodeDiscount ? 0.01 :
        this.products.total_brutto
    },
  },
  methods: {
   
    ...mapMutations({
      DELETE_CART_PRODUCT: 'cart/DELETE_CART_PRODUCT',
    }),

    goToCheckout() {
      if (this.isAuth) {
        this.$router.push(this.localePath('/checkout'))
        return false
      }
      this.$refs.loginModal.handleClick()
    },
    async  fetchCartProducts() {
      try {
        const response = await  this.$api.cart.getItemsCart() 
        const items = response.data.items.map((item) => {
          return {
            ...item,
            price_type: {
              brutto: item.brutto,
              discount_brutto: item.brutto_discount,
              netto: item.netto,
              discount_netto: item.netto_discount,
            },
          }
        })
        const cart = {
          loyalty_points: response.data.loyalty_points,
          loyalty_points_discount: response.data.loyalty_points_discount,
          promo_code: response.data.promo_code,
          promo_code_type: response.data.promo_code_type,
          promo_code_discount: response.data.promo_code_discount,
          total_brutto: response.data.total_brutto,
          total_netto: response.data.total_netto_without_discount,
          total_vat: response.data.total_vat,
          items,
        }
        this.products = cart

        if (this.isAuth) {
          const responseLoyalty = await this.$api.loyalty.getLoyaltyProgram()
          this.loyalty = responseLoyalty.data
          this.priceType = this.loyalty.settings.price_type
        }
      } catch (error) {
        // console.log(error)
      }
    },
    favoriteStroke(id) {
      return id ? this.$color.destructiveRed : this.$color.dark
    },
    favoriteFill(id) {
      return id ? this.$color.destructiveRed : 'none'
    },
    async selectFavorite(product) {
      this.selectProduct = product.slug
      const payload = {
        entity: 'product',
        slug: product.slug,
      }
      try {
        if(!product.favorite_id) {
          const res = await this.$api.favorites.addFavorites(payload)
          product.favorite_id = res.data.id
        } else {
          await this.$api.favorites.deleteFavorites(product.favorite_id)
          product.favorite_id = null
        }
      } catch (error) {
        // console.log(error)
      }
    },
    increaseItem(product) {
      this.selectProduct = product.slug
      const payload = {
        entity: product.set_name && product.set_slug ? 'set' : 'product',
        slug: product.set_name && product.set_slug ? product.set_slug : product.slug,
        ...this.getTempUid,
      }
      try {
        this.$api.cart.addItemCart(payload)
        clearTimeout(this.debounce)
        this.debounce=setTimeout(()=> this.fetchCartProducts(),1500)
      } catch (error) {
        // console.log(error)
      }
    },
    decreaseItemCart(product) {
      this.selectProduct = product.slug
      const payload = {
        entity: product.set_name && product.set_slug ? 'set' : 'product',
        slug: product.set_name && product.set_slug ? product.set_slug : product.slug,
        ...this.getTempUid,
      }
      try {
        this.$api.cart.decreaseItemCart(payload)
        clearTimeout(this.debounce)
        this.debounce=setTimeout(()=> this.fetchCartProducts(),1500)
      } catch (error) {
        // console.log(error)
      }
    },
    async deleteItem(id, slug) {
      try {
        await this.$api.cart.deleteItemCart(id)
        this.products.items = this.products.items.filter(item => item.id !== id)
        this.$store.commit('cart/DELETE_CART_PRODUCT', slug)
        if(this.products.items.length === 0 && !!this.products.loyalty_points) {
          this.$refs.loyaltyPoints.deleteLoyaltyProgram()
        }
        if(this.products.items.length === 0 && !!this.products.promo_code) {
          this.$refs.promoCode.deletePromocode()
        }
        this.fetchCartProducts()
      } catch (error) {
        // console.log(error)
      }
    },
    async clearCart() {
      try {
        await this.$api.cart.clearCart()
        this.$store.commit('cart/CLEAR_CART_PRODUCT')
        if(this.products.items.length === 0 && !!this.products.loyalty_points) {
          this.$refs.loyaltyPoints.deleteLoyaltyProgram()
        }
        if(this.products.items.length === 0 && !!this.products.promo_code) {
          this.$refs.promoCode.deletePromocode()
        }
        this.fetchCartProducts()
      } catch (error) {
        // console.log(error)
      }
    },
    mounted() {
      this.fetchCartProducts()
    },
    
  },

}
