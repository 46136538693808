//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { helpers, required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import validationWatcher from '~/mixins/validationWatcher'
import getCode from '~/mixins/getCode'
import Table from '~/components/UI/Table.vue'
import AddPromocode from '~/components/modules/Cart/AddPromocode.vue'
import ApplyLoyaltyProgram from '~/components/modules/Cart/ApplyLoyaltyProgram.vue'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import ModalCart from '~/components/modules/ModalWindows/ModalCart.vue'
import ModalEditDeliveryMethod from '~/components/modules/ModalWindows/profile/checkout/ModalEditDeliveryMethod.vue'
import ModalEditPayment from '~/components/modules/ModalWindows/profile/checkout/ModalEditPayment.vue'
import ModalEditPaymentMethod from '~/components/modules/ModalWindows/profile/checkout/ModalEditPaymentMethod.vue'
import ModalEditComment from '~/components/modules/ModalWindows/profile/checkout/ModalEditComment.vue'
import ModalEditCashOnDelivery from '~/components/modules/ModalWindows/profile/checkout/ModalEditCashOnDelivery.vue'
import ModalEditDeliveryAddress from '~/components/modules/ModalWindows/profile/checkout/ModalEditDeliveryAddress.vue'
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\-\s]*$/)
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)

export default {
  components: {
    Table,
    AddPromocode,
    ApplyLoyaltyProgram,
    DropdownSelect,
    ModalCart,
    ModalEditDeliveryMethod,
    ModalEditPayment,
    ModalEditPaymentMethod,
    ModalEditComment,
    ModalEditCashOnDelivery,
    ModalEditDeliveryAddress,
  },
  mixins: [validationMixin, getCode, validationWatcher],
  middleware: ['auth'],
  async asyncData({ $api, redirect, app }) {
    try {
      const responseLoyalty = await $api.loyalty.getLoyaltyProgram()
      const companyInfo = await $api.user.getCompanyInfo()
      const productsCart = await $api.cart.getItemsCart()
      const deliveryAddresses = await $api.user.getCompanyDeliveryAddresses()
      const getPaymentMethod = await $api.cart.getPaymentMethod()
      const getDeliveryMethod = await $api.cart.getDeliveryMethod()
      const deliveryAddressesNames = deliveryAddresses.data.map((item, i) => {
        return { key: i, description: item.description, type: item.is_default ? app.i18n.t('defaultAddress') : item.description.replace(`${app.i18n.t('company')}`, '')  }
      })

      if(productsCart.data.length === 0) {
        redirect('/cart')
      }

      const items = productsCart.data.items.map((item) => {
        return {
          ...item,
          price_type: {
            brutto: item.brutto,
            discount_brutto: item.brutto_discount,
            netto: item.netto,
            discount_netto: item.netto_discount,
          },
        }
      })
      const cart = {
        loyalty_points: productsCart.data.loyalty_points,
        loyalty_points_discount: productsCart.data.loyalty_points_discount,
        promo_code: productsCart.data.promo_code,
        promo_code_discount: productsCart.data.promo_code_discount,
        total_brutto: productsCart.data.total_brutto,
        total_netto: productsCart.data.total_netto_without_discount,
        total_vat: productsCart.data.total_vat,
        promo_code_type: productsCart.data.promo_code_type,
        items,
      }
      return {
        loyalty: responseLoyalty.data,
        products: cart,
        payment: getPaymentMethod.data[0].name,
        deliveryMethod: getDeliveryMethod.data[0].name,
        getPaymentMethods: getPaymentMethod.data,
        getDeliveryMethods: getDeliveryMethod.data,
        deliveryAddresses: deliveryAddresses.data,
        deliveryAddressesNames,
        companyInfo: companyInfo.data,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      getPaymentMethods: [],
      loyalty: {},
      products: null,
      openCartModal: false,
      orderType: 'Standard',
      deliveryMethod: 'Interni CARGO',
      payment: 'PayU',
      paymentMethod: 'PayU',
      currentStep: 0,
      deliveryAddress: '',
      clientPay: null,
      companyPay: null,
      extraCharge: null,
      loadingBtnAsNewDeliveryAddress: false,
      loadingBtnOverwriteCurrent: false,
      deliveryAddressId: null,
      is_default: false,
      comment: '',
      orderData: null,
      companyInfo: null,
      addressDoesNotExist: false,
      errorRedirectToPayU: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        code: '',
        city: '',
        phone: '',
        street: '',
        building: '',
        apartment: '',
        voivodeship: '',
        company_name: '',
        description: '',
        deliveryAddress: '',
      },
      orderTypes: ['Standard', 'Dropshipping'],
      paymentType: ['Full payment', 'Client full payment', 'Combined payment'],
      fields: [
        {
          key: 'files',
          label: this.$t('product'),
          width: '68px',
        },
        {
          key: 'name',
          label: '',
          width: '340px',
        },
        {
          key: 'quantity',
          label: this.$t('qty'),
          width: '100px',
        },
        {
          key: 'sum',
          label: this.$t('totalPrice'),
          width: '180px',
        },
      ],
      headerList: [
        {
          name: this.$t('deliveryAndPayment'),
          active: true,
          selected: false,
          visible: true,
        },
        {
          name: this.$t('deliveryInformation'),
          active: false,
          selected: false,
          visible: false,
        },
        {
          name: this.$t('orderConfirmation'),
          active: false,
          selected: false,
          visible: false,
        },
      ],

    }
  },
  validations() {
    const rules = {
      form: {
        building: { required, maxLength: maxLength(10) },
        apartment: { maxLength: maxLength(10) },
        city: { required, minLength: minLength(2), maxLength: maxLength(100) },
        code: { required },
        first_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        last_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        company_name: { minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        street: { required, minLength: minLength(2), maxLength: maxLength(100) },
        voivodeship: { required, minLength: minLength(2), maxLength: maxLength(100) },
        email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
        description: { },
        phone: { required, minLength: minLength(10) },
      },
    }

    return rules
  },
  computed: {
    ...mapState({
      constants: state => state.cart.constants,
    }),
    errorClientPay() {
      return Number(this.clientPay) + Number(this.companyPay) < this.toPayment
    },
    errorClientPayText() {
      return this.clientPay && this.errorClientPay ? [this.$t('errorClientPay')] : null
    },
    getPaymentMethod() {
      return this.getPaymentMethods.find(method =>
        method.name === this.orderType === this.constants.ORDER_TYPE_STANDARD ? this.payment : this.paymentMethod)
    },
    getDeliveryMethod() {
      return this.getDeliveryMethods.find(method => method.name === this.deliveryMethod)
    },
    productsPrice() {
      return this.products.total_netto + this.products.total_vat
    },
    getPromocodePercent() {
      return (this.products.total_netto + this.products.total_vat) / 100 * 10
    },
    getPromocodeDiscount() {
      if(this.products.promo_code_type === 1 && this.products.promo_code_discount) {
        return this.getPromocodePercent
      }
      if(this.products.promo_code_type === 2 && this.products.promo_code_discount) {
        return this.products.promo_code_discount
      }

      return 0
    },
    getExtraCharge() {
      if(this.clientPay && this.payment === this.constants.PAYMENT_TYPE_CLIENT_FULL_PAYMENT) {
        return (Number(this.clientPay) - this.toPayment)
      } else if (this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT) {
        return ((Number(this.companyPay) + Number(this.clientPay)) - this.toPayment)
      } else {
        return 0
      }
    },
    getDeliveryMethodAndPaymentMethod() {
      return this.currentStep !== 0 ?
        this.getPaymentMethod.value + (this.products.promo_code_type !== 3 ? this.getDeliveryMethod.value : 0) : 0
    },
    toPayment() {
      return this.products.loyalty_points_discount >= this.productsPrice - this.getPromocodeDiscount ?
        0.01 + this.getDeliveryMethodAndPaymentMethod :
        (this.productsPrice - this.getPromocodeDiscount - this.products.loyalty_points_discount)
          + this.getDeliveryMethodAndPaymentMethod
    },

    totalToPayment() {
      return this.toPayment + this.getExtraCharge
    },
  },
  watch: {
    orderType(val) {
      this.payment = val === this.constants.ORDER_TYPE_STANDARD ? 'PayU' : this.constants.PAYMENT_TYPE_FULL_PAYMENT
    },
    'form.deliveryAddress': {
      handler(value) {
        const deliveryAddress = this.deliveryAddressesNames.find(address => address.type === value)
        this.fillFormDeliveryAddress(deliveryAddress && deliveryAddress.description ? deliveryAddress.description : '')
      },
    },
    clientPay(val) {
      if (val.includes(',')) {
        this.clientPay = val.replace(/,/g, '.')
      }
    },
    companyPay(val) {
      if (val.includes(',')) {
        this.companyPay = val.replace(/,/g, '.')
      }
    },
    // 'form.code': {
    //   handler() {
    //     this.getCode()
    //   },
    // },
  },
  mounted() {
    this.deliveryAddresses.forEach(address => {
      if(address.is_default) {
        this.form.deliveryAddress = this.$t('defaultAddress')
        this.fillFormDeliveryAddress(address.description)
      }
    })
  },
  methods: {
    async fetchCartProducts() {
      try {
        const response = await this.$api.cart.getItemsCart()
        this.products = response.data
        this.products.total_netto = response.data.total_netto_without_discount

        const responseLoyalty = await this.$api.loyalty.getLoyaltyProgram()
        this.loyalty = responseLoyalty.data
      } catch (error) {
        // console.log(error)
      }
    },
    async createOrder() {
      if(this.payment === this.constants.PAYMENT_TYPE_CLIENT_FULL_PAYMENT && !this.clientPay ||
          this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT && (!this.clientPay || !this.companyPay)) {
        this.editCashOnDelivery()
        return
      }
      const form = {
        type: this.orderTypes.findIndex(type => type === this.orderType) + 1,
        receiver: {
          first_name: this.form.first_name,
          second_name: this.form.last_name,
          email: this.form.email,
          code: this.form.code,
          street: this.form.street,
          building: this.form.building,
          apartment: this.form.apartment,
          description: this.form.description,
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
        },
        delivery_method_id: this.getDeliveryMethod.id,
        payment_method_id: this.getPaymentMethod.id,
        comment: this.comment,
      }
      if(this.payment === this.constants.PAYMENT_TYPE_CLIENT_FULL_PAYMENT ||
        this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT) {
        form.client_pay = this.clientPay
        form.extra_charge = this.getExtraCharge
      }

      if(this.payment === this.constants.PAYMENT_TYPE_COMBINED_PAYMENT) {
        form.company_pay = this.companyPay
      }

      if(this.orderType === this.constants.ORDER_TYPE_STANDARD && this.deliveryAddressId) {
        form.receiver.id = this.deliveryAddressId
        form.receiver.company_name = this.form.company_name
      }

      if(this.orderType === this.constants.ORDER_TYPE_STANDARD) {
        form.payment_type = 1
      } else {
        form.payment_type = this.paymentType.findIndex(type => type === this.payment) + 1
      }

      for (const propName in form) {
        if (form[propName] === '' || form[propName] === null) {
          delete form[propName]
        }
      }
      try {
        const res = await this.$api.cart.createOrderOnCheckout(form)
        if(res.data.redirect_type === 'internal') {
          this.$router.push({path: this.localePath('/checkout/success'), query: {order: res.data.redirect_uri}})
          this.$store.commit('cart/CLEAR_CART_PRODUCT')
        }
        if(res.data.redirect_type === 'external') {
          window.open(res.data.redirect_uri,'_blank')
          this.$store.commit('cart/CLEAR_CART_PRODUCT')
        } else {
          this.errorRedirectToPayU = true
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async saveOverwriteCurrent() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      const payload = {
        is_default: this.is_default,
        address: {
          ...this.form,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          street: this.form.street,
        },
      }

      delete payload.address.deliveryAddress

      try {
        this.loadingBtnOverwriteCurrent = true
        await this.$api.user.updateAddress(payload, this.deliveryAddressId)
        this.setValidationSettings(false)
        this.loadingBtnOverwriteCurrent = false
      } catch (error) {
        this.loadingBtnOverwriteCurrent = false
        // console.log(error)
      }
    },
    async saveAsNewDeliveryAddress() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return

      const payload = {
        is_default: this.is_default,
        address: {
          ...this.form,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
          city: this.form.city,
          voivodeship: this.form.voivodeship,
          street: this.form.street,
          description: `${this.$t('default')} ${this.$t('deliveryAddress')} ${this.deliveryAddresses.length}`,
        },
      }

      delete payload.address.deliveryAddress
      delete payload.address.company_name

      try {
        this.loadingBtnAsNewDeliveryAddress = true
        await this.$api.user.createAddress(payload)
        this.setValidationSettings(false)
        this.loadingBtnAsNewDeliveryAddress = false
        this.getDeliveryAddresses()
        await this.$nuxt.refresh()
      } catch (error) {
        this.loadingBtnAsNewDeliveryAddress = false
        // console.log(error)
      }

    },
    fillFormDeliveryAddress(description) {
      const address = this.deliveryAddresses.find((address) => address.description === description)
      if(address) {
        this.deliveryAddressId = address.id
        this.is_default = address.is_default
        this.form.company_name = this.companyInfo.name
        this.form.first_name = address.first_name
        this.form.last_name = address.last_name
        this.form.email = this.companyInfo.email
        this.form.code = address.code
        this.form.city = address.city
        this.form.phone = address.phone
        this.form.street = address.street
        this.form.building = address.building
        this.form.apartment = address.apartment
        this.form.voivodeship = address.voivodeship
        this.form.description = address.description
        this.addressDoesNotExist = false
      } else {
        this.form.description = this.form.deliveryAddress.type
        this.addressDoesNotExist = true
      }
    },
    clearForm() {
      this.deliveryAddressId = null
      this.is_default = false
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        code: '',
        city: '',
        phone: '',
        street: '',
        building: '',
        apartment: '',
        voivodeship: '',
        comment: '',
        deliveryAddress: '',
      }
    },
    getOrderType() {
      return this.orderType === this.constants.ORDER_TYPE_STANDARD ? 'Payment on delivery' : this.constants.PAYMENT_TYPE_FULL_PAYMENT
    },
    editDeliveryMethod() {
      this.$refs.modalEditDeliveryMethod.onOpen(this.deliveryMethod)
    },
    editPayment() {
      this.$refs.modalEditPayment.onOpen(this.payment)
    },
    editPaymentMethod() {
      this.$refs.modalEditPaymentMethod.onOpen(this.paymentMethod)
    },
    editComment() {
      this.$refs.modalEditComment.onOpen(this.comment)
    },
    editCashOnDelivery() {
      const form = {
        companyPay: this.companyPay,
        clientPay: this.clientPay,
      }
      this.$refs.modalEditCashOnDelivery.onOpen(form)
    },
    editDeliveryAddress() {
      this.$refs.modalEditDeliveryAddress.onOpen(this.form, this.deliveryAddressId)
    },
    onChangeDeliveryMethod(value) {
      this.deliveryMethod = value
    },
    onChangePayment(value) {
      this.payment = value
    },
    onChangePaymentMethod(value) {
      this.paymentMethod = value
    },
    onChangeComment(value) {
      this.comment = value
    },
    onChangeCashOnDelivery(form) {
      this.companyPay = form.companyPay
      this.clientPay = form.clientPay
    },
    onChangeDeliveryAddress(form) {
      this.form = form
    },
    openModalCart() {
      this.openCartModal = !this.openCartModal
      this.$refs.modalCart.onOpen(this.products)
    },
    prevStep() {
      if(this.$refs.nextStep) {
        this.$refs.nextStep.$el.classList.remove('button--disabled')
      }
      if(this.currentStep !== 0) {
        this.headerList[this.currentStep].active = false
        this.headerList[this.currentStep].selected = false
        this.headerList[this.currentStep].visible = false
        this.headerList[this.currentStep - 1].visible = true
        this.headerList[this.currentStep - 1].selected = false
        this.currentStep--
      }
    },
    nextStep() {
      if(this.headerList[2].visible) {
        this.setValidationSettings(true)
        if (!this.checkForm()) return
        this.setValidationSettings(false)
      }
      this.currentStep++
      this.headerList[this.currentStep - 1].visible = false
      this.headerList[this.currentStep - 1].selected = true
      this.headerList[this.currentStep].active = true
      this.headerList[this.currentStep].visible = true
    },
    async getDeliveryAddresses() {
      try {
        const res = await this.$api.user.getCompanyDeliveryAddresses()
        this.deliveryAddresses = res.data
        this.fillFormDeliveryAddress(this.form.description)
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
